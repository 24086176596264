a {
  color: black;
  text-decoration: none !important;
}
a,
button {
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
}
