.elementor-46
  .elementor-element.elementor-element-eeaa401
  .elementor-repeater-item-470898e
  .tp-service-item__button-link
  a
  span::after {
  background-color: #33cc79;
}
.elementor-46
  .elementor-element.elementor-element-eeaa401
  .elementor-repeater-item-f9579d1
  .tp-service-item__icon
  span {
  background-color: #6864ed;
}

.elementor-46 .elementor-element.elementor-element-eeaa401 .elementor-repeater-item-f9579d1 .tp-service-item__button-link a span::after {
    background-color: #6864ED;
}