.loader-wrapper{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    background: rgb(0, 0, 0,0.2);
    z-index: 111111;

}
.spinner {
    border: 0 solid transparent;
    border-radius: 70%;
    width: 70px;
    height: 70px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
   }
   
   .loader {
    width: inherit;
    height: inherit;
    position: absolute;
   }
   
   .loader::before,
   .loader::after {
    content: '';
    border: 3px solid #1869b3 ;
    border-radius: 50%;
    width: inherit;
    height: inherit;
    position: absolute;
    opacity: 1;
   }
   
   .l1::before,
   .l1::after {
    animation: clockwiseZ 2.5s infinite;
   }
   
   .l2::after,
   .l2::before {
    animation: anticlockwiseZ 2.5s infinite;
   }
   
   @keyframes clockwiseZ {
    0%, 100% {
     transform: rotateY(0);
    }
   
    50% {
     transform: rotateY(180deg) skew(-10deg, -5deg);
    }
   }
   
   @keyframes anticlockwiseZ {
    0%, 100% {
     transform: rotateX(0);
    }
   
    50% {
     transform: rotateX(-180deg) skew(10deg, 5deg);
    }
   }