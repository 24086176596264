body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: #f0f3f5;
  padding-right: 0px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// :root{
// }

$primary-color: rgb(24, 105, 179);
$primary-hover-color: rgb(25, 92, 169);
$primary-r: 112;
$primary-g: 101;
$primary-b: 240;
$primary-dark-color: #110b55;
$primary-text-color: #000929;
$gray-color: #808494;
$primary-text-gray-color: #808494;
$background-color: #ffffff;
$secondary-text-color: #ffffff;
$secondary-btn-color: #ffffff;
$transition-time: 150ms;

.primary-btn {
  background-color: $primary-color;
  color: $secondary-text-color;
  height: 40px;
  font-family: Poppins;

  // font-size: 14px;
  font-weight: 500;
  padding: 0px 20px;
  border-radius: 8px;
  transition: all ease-in 200ms;
  border: none;
  &:hover {
    background-color: $primary-hover-color;
  }
}
.secondary-btn {
  background-color: transparent;
  color: $primary-color;
  height: 40px;
  font-family: Poppins;

  // font-size: 14px;
  font-weight: 500;
  padding: 10px 40px;

  border-radius: 4px;
  transition: all ease-in $transition-time;
  border: 2px solid rgb($primary-r, $primary-g, $primary-b, 0.2);

  &:hover {
    background-color: $primary-hover-color;
    color: $secondary-text-color;
  }
}
.green-btn {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: var(--Green, #28d024);
  display: flex;
  padding: 10px 40px;
  align-items: center;
  gap: 10px;
  color: var(--Background, #f8f9fa);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.18px;
  transition: all ease-in 200ms;
  width: fit-content;
  &:hover {
    background-color: #29bb25;
  }
}

a {
  
  text-decoration: none !important;
  
}
.errorMsg {
  padding: 5px 10px;
  background-color: #fee3e1;
  color: #f44336;
  font-size: 12px;
  border-radius: 0px 8px 8px 8px;
  font-weight: 500;
  width: min(330px,100%);
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0; /* Optional: You can adjust the margin to control spacing */
}
input:disabled {
  opacity: 0.5;
}
#svg-icon-chevron-single-up-down{
  width: 12px;
}