@import "../../index.scss";

.footer {
  display: flex;
  margin: auto;
  gap: 120px;
  padding: 60px 10%;
  flex-direction: row;
  justify-content: space-between;
  background: #191919;
  color: white;

  .logo {
    color: #fff;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    hr {
      margin: 5px;
    }
    .social-links {
      display: flex;
      justify-content: space-between;
      // gap: 10px;
      margin: 20px 0px 10px 0px;
    }
    .policy-n-tnc{
      display: flex;
      justify-content: space-around;
      // gap: 10px;
      margin-top: 10px;
      flex-direction: column;
      span {
        font-size: 15px;
      }
    }
  }
  .contect-detail-wrapper {
    width: 300px;
    display: flex;
    flex-direction: column;
    grid-area: 10px;
    .contect-detail {
      display: flex;
      gap: 10px;
      // justify-content: center;
      // align-items: center;
      span {
        display: flex;
        align-items: center;
        color: var(--White, #fff);
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 171.5%; /* 20.58px */
        letter-spacing: 0.3px;
      }
    }
  }
}
.rights-container {
  width: 100%;
  // max-width: 1280px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  border-top: 2px solid rgb(128, 128, 128, 0.2);
  color: white;
  background: #191919;
  padding-bottom: 30px;
  .rights {
    width: 100%;
    color: #fff;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 171.5%; /* 17.15px */
    letter-spacing: 0.25px;
    text-align: center;
  }
}

@media only screen and (max-width: 992px) {
  .footer{
  // padding: 60px ;

  }
}
@media only screen and (max-width: 765px) {
  .footer {
    gap: 50px;
    flex-direction: column;
    .logo {
      img {
        width: 174px;
      }
    }
    .nav-link-container {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .nav-link-card {
        width: 250px;

        font-size: 14px;
        .heading {
          color: $primary-text-color;
          font-weight: 700;
          margin-bottom: 15px;
        }
        .nav-link {
          display: flex;
          flex-direction: column;
          a {
            text-decoration: none;
            color: $primary-text-gray-color;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .footer {
    gap: 50px;
    flex-direction: column;
    .logo {
      img {
        width: 120px;
      }
    }
    .nav-link-container {
      display: flex;
      flex-wrap: wrap;

      .nav-link-card {
        width: 150px;

        font-size: 16px;
        .heading {
          color: $primary-text-color;
          font-weight: 700;
          margin-bottom: 15px;
        }
        .nav-link {
          display: flex;
          flex-direction: column;
          a {
            text-decoration: none;
            color: $primary-text-gray-color;
          }
        }
      }
    }
  }
  .social-link-container {
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    .rights {
      margin: auto;
    }
    .social-links {
      margin: auto;
      svg {
        height: 30px;
        width: 30px;
      }
    }
  }
}
