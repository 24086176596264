.mean-nav ul {
    padding-left:  0;
  li {
    padding: 15px 0px;
    a {
      padding: 15px 0;
      width: 100%;
      color: #fff;
    //   border-top: 1px solid rgba(255, 255, 255, 0.1);
      font-size: 14px;
      line-height: 1.5;
      font-weight: 700;
      &:hover {
        color: #6864ed;
      }
    }
  }
}

.mean-nav > ul > li:first-child > a {
  border-top: 0;
}
